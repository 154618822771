.App {
  position: relative;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}




