*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.clock {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20.4em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  /* border: 0.2em solid #000; */
  background: linear-gradient(90deg, #29323c 0%, #485563 100%);
}

.numbers {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.number {
  font-size: 2.5em;
}

.number:nth-child(1),
.number:nth-child(2),
.number:nth-child(3),
.number:nth-child(4) {
  position: absolute;
  transform-origin: 50% 100%;
}

.number:nth-child(1) {
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.number:nth-child(2) {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.number:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.number:nth-child(4) {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.hand {
  position: absolute;
  background-color: #fff;
  transform-origin: bottom center;
}

.hour-hand {
  width: 0.1em;
  height: 7.1em;
  left: calc(50% - 0.2em);
  bottom: 50%;
  transform: translateY(-50%);
}

.minute-hand {
  width: 0.1em;
  height: 10em;
  left: calc(50% - 0.15em);
  bottom: 50%;
  transform: translateY(-50%);
}

.second-hand {
  width: 0.1em;
  height: 6.5em;
  left: calc(50% - 0.05em);
  bottom: 50%;
  transform: translateY(-50%);
  background-color: red;
  transition: all 0.5s ease;
}

.minute-line {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: 1px;
  height: 20px;
  background-color: #fff;
  transform-origin: bottom;
}

.minute-line::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: 1px;
  height: 20px;
  background-color: #fff;
  transform-origin: bottom;
}

@media screen and (max-width: 576px) {
  .clock {
    width: 13px;
    height: 13px;
    margin-top: 21.7em !important;
  }

  .hour-hand {
    height: 55px;
  }

  .minute-hand {
    height: 78px;
  }

  .second-hand {
    height: 92px;
  }
  .minute-line {
    height: 12px;
  }
}
@media screen and (max-width: 480px) {
  .clock {
    margin-top: 18em;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .clock {
    top: -14em;
  }
  .hour-hand {
    height: 50px;
  }
  .minute-hand {
    height: 65px;
  }

  .second-hand {
    height: 70px;
  }
  .minute-line {
    height: 10px;
  }
}

@media only screen and (max-width: 684px) and (orientation: landscape) {
  .clock {
    top: -14.5em !important;
    left: -1em !important;
  }
}

@media only screen and (min-width: 620px) and (max-width: 768px) {
  .clock {
    margin-top: 27.7em;
  }
}
