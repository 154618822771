@import url("https://fonts.cdnfonts.com/css/poppins");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.hero-container {
  width: 100%;
  height: 105vh;
  position: relative;
  background-image: url(../../assests/images/avvic-hero-desktop-2.png);
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  overflow-y: hidden;
}

.hero-content-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 14.9em;
  color: #fff;
}
.hero-content-heading {
  font-size: 38px;
  padding-top: 11px;
  padding-bottom: 13px;
  letter-spacing: 10px;
}
.hero-content {
  font-size: 11px;
  letter-spacing: 7px;
  line-height: 28px;
}
.stay-tuned {
  letter-spacing: 5px;
}

.social-mediaIcon-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 41.44em;
  gap: 0.5em;
  width: 100%;
}
.social-column {
  padding: 4px;
  align-self: baseline;
  transition: transform 0.2s;
}

.social-column:hover {
  transform: scale(1.2);
}
/* SECTION FOR SEAMLESS BANKING */
.info-container {
  height: 100vh;
  padding-left: 60px !important;
  padding-right: 60px !important;
  padding-top: 30px;
  padding-bottom: 60px !important;
  background: linear-gradient(90deg, #fffeff 0%, #d7fffe 100%);
  overflow-x: hidden;
  overflow-y: hidden;
}
.info-heading {
  font-size: 48px;
  font-weight: 700;
  padding-top: 7%;
  line-height: 57.6px;
  color: #353c5b;
}
.info-heading2 {
  color: #626364;
  margin-top: 1%;
}

.info-content-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 6%;
  width: 100%;
}

.column:nth-child(1),
.column:nth-child(2),
.column:nth-child(3) {
  flex: 1;
}
.icon {
  width: 60px;
  height: 55px;
}
.second-icon {
  width: 70.67px !important;
  height: 52.67px;
}
.info-content-heading {
  color: #353c5b;
  font-weight: 700;
  text-align: center;
  font-size: 32px;
  margin-top: 6%;
}
.info-content {
  color: #626364;
  padding: 23px;
  font-size: 16px;
  text-align: center;
}

.avvic-logo-2-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 40px;
  padding-left: 60px !important;
}
.avvic-logo-2 {
  width: 196px;
  height: 64px;
}


@media screen and (max-width: 576px) {
  .hero-container {
    background-image: url(../../assests/images/avvic-hero-mobile-2.png);
    background-position: center;
  }

  .hero-content-container {
    margin-top: 17.9em !important;
  }
  .hero-content-heading {
    font-size: 20px;
    padding-top: 7px;
    text-align: center;
    padding-top: 18px;
    padding-bottom: 15px;
    letter-spacing: 4px;
  }
  .hero-content {
    font-size: 8px;
    letter-spacing: 6px;
    line-height: 15px;
    transform: bottom;
  }
  .stay-tuned {
    padding-top: 0;
    letter-spacing: 5px;
  }

  .social-mediaIcon-container {
    margin-top: 35em;
    gap: 0.3em;
    width: 100%;
  }
  .social-column {
    padding: 3px;
  }

  .avvic-logo-2 {
    width: 186px;
    height: 60px;
    margin-top: 13%;
    margin-left: 1%;
  }
  .info-heading {
    font-size: 35px;
    font-weight: 600;
  }
  .info-heading2 {
    margin-top: 1%;
  }
  .info-container {
    padding-top: 22px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 5px !important;
    height: auto;
    overflow-x: hidden;
  }
  .info-content-container {
    display: block;
    margin-top: 13%;
  }
  .column:nth-child(2) {
    margin-bottom: 12%;
    margin-top: 12%;
  }
  .icon {
    width: 45.08px;
    height: 52.5px;
  }
  
  .info-content-heading {
    font-weight: 500;
    font-size: 25px;
    margin-top: 3%;
  }
  .info-content {
    line-height: 28px;
    text-align: justify;
    padding-top: 10px;
    font-size: 12px;
  }

  /* FOOTER CONTENT IS BELOW */
  .avvic-logo-2-container {
    padding-top: 17px;
    padding-left: 5px !important;
  }
 
}
@media screen and (max-width: 480px) {
  .hero-container {
    width: 100%;
    height: 94vh;
  }
  
  .social-mediaIcon-container {
    margin-top: 38.3em;
  }
}

@media only screen and (max-width: 874px) and (orientation: landscape) {
  .hero-container {
    width: 100%;
    height: 115vh !important;
  }
  .hero-content-container {
    top: -12em;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .hero-content-heading {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    letter-spacing: 10px;
  }
  .hero-content {
    font-size: 9px;
    letter-spacing: 7px;
    line-height: 20px;
  }
  .social-mediaIcon-container {
    top: -16em;
  }
  .info-container {
    height: auto;
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .info-heading {
    font-size: 35px;
    padding-top: 6%;
    line-height: 50.6px;
  }
  .icon {
    width: 50px;
    height: 50px;
  }
  .info-content-heading {
    font-size: 18px;
    margin-top: 4%;
  }
  .info-content {
    padding: 15px;
    font-size: 13px;
    text-align: center;
  }
}

/* MEDIA QUERY */

@media only screen and (min-width: 620px) and (max-width: 785px) {
  .hero-container {
    overflow-y: hidden;
  }

  .hero-content-container {
    margin-top: 23.5em;
    font-size: 13px;
    letter-spacing: 6px;
  }
  .social-mediaIcon-container {
    margin-top: 55em;
  }

  .info-container {
    width: 100%;
    padding-top: 60px !important;
    padding-left: 45px !important;
    padding-right: 45px !important;
    padding-bottom: 40px !important;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 684px) and (orientation: landscape) {
  .hero-content-container {
    margin-top: 22.4em !important;
  }
}