.contact-us {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 9px;
  padding: 12px;
  gap: 8px;
}

.contact-form {
  width: 26em !important;
  height: 40px;
  border-radius: 4px;
  padding: 12px !important;
  border-color: #626364;
}

.contact-btn {
  width: 130px !important;
  height: 42px;
  margin-left: 5px;
  border-radius: 10px !important;
  background-color: #ffff;
}
.contact-btn:hover {
  background-color: rgb(24, 96, 24) !important;
  color: #fff;
}
.subscribe-content {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #626364;
  text-decoration: underline;
  padding: 10px;
}

@media screen and (max-width: 576px) {
  .contact-us {
    margin-top: 7px;
    padding: 2px;
    gap: 3px;
  }

  .contact-form {
    width: 67.99999% !important;
    height: 40px;
    padding: 18px;
    margin-left: 12px !important;
  }
  .contact-btn {
    width: 24.8% !important;
    height: 42px;
    font-size: 12px;
  }
  .subscribe-content {
    font-size: 11px;
    padding-left: 20px !important;
    text-decoration: underline;
    word-break: break-word;
  }
}

@media only screen and (max-width: 798px) and (orientation: landscape) {
  .contact-form {
    width: 97% !important;
  }

  .contact-btn {
    font-size: 12px;
  }
}

@media only screen and (max-width: 684px) and (orientation: landscape) {
  .contact-form {
    width: 97% !important;
  }
}

@media only screen and (min-width: 620px) and (max-width: 768px) {
  .contact-form {
    width: 10.25rem !important;
    height: 40px;
    border-radius: 4px;
    padding: 7px;
  }

  .contact-btn {
    width: 78px !important;
    height: 42px;
  }
}

@media only screen and (min-width: 810px) and (max-width: 999px) {
  .contact-form {
    width: 190px !important;
    height: 40px;
    padding: 5px;
  }
  .contact-btn {
    width: 75px !important;
    height: 42px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1226px) {
  .contact-form {
    width: 20em !important;
    height: 44px;
    padding: 8px;
  }

  .contact-btn {
    width: 75px !important;
    height: 45px;
    margin-left: 4px;
  }
}
