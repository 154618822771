.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.open {
  display: block;
}

.modal-content {
  background-color: #fff;
  color: black;
  margin: 10% auto;
  padding: 15px;
  border: 1px solid #888;
  border-radius: 12px;
  width: 95%;
  max-width: 500px;
  position: fixed;
  z-index: 999999;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 10em;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.subscribe-head-text, .subscribe-text-content{
  color: #353c5b;
}
.formInput-container{
  padding: 5px;
}

.newsfeed-form {
  width: 80% ;
  height: 58px !important ;
  padding: 8px;
  border-radius: 4px;
  margin-top: 5px;
   margin-bottom: 10px;
   border-color: rgb(98, 140, 154);
}

.phoneInput{
  margin-top: 10px;
}

.subscribe-btn {
  width: 210px;
  height: 43px;
  border-radius: 4px;
  color: #fff;
  background-color: #0d9999;
  margin-top: 1em;
}
.subscribe-btn:hover {
  background-color: rgb(24, 96, 24) !important;
}

@media screen and (max-width: 576px) {
  .modal-content {
    margin: 54% auto;
  }

  .newsfeed-form {
    width: 90% !important;
    height: 42px;
    padding: 10px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media only screen and (max-width: 798px) and (orientation: landscape){
  .modal-content {
    margin: 15% auto;
  }
}
